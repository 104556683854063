// Generated by Framer (705c3f6)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["E2OQLO3U4", "BKwjpwvYJ"];

const serializationHash = "framer-ZpznJ"

const variantClassNames = {BKwjpwvYJ: "framer-v-sj6sf", E2OQLO3U4: "framer-v-ywxmd1"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const transformTemplate1 = (_, t) => `translate(-50%, -50%) ${t}`

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {"Variant 1": "E2OQLO3U4", "Variant 2": "BKwjpwvYJ"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "E2OQLO3U4"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "E2OQLO3U4", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-ywxmd1", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"E2OQLO3U4"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({BKwjpwvYJ: {"data-framer-name": "Variant 2"}}, baseVariant, gestureVariant)}><motion.div className={"framer-2tewi9"} layoutDependency={layoutDependency} layoutId={"xYByy_HbU"} style={{backgroundColor: "var(--token-d359b356-1fb5-4f86-96a1-29cb6ad6a6dc, rgb(242, 241, 244))", borderBottomLeftRadius: "100%", borderBottomRightRadius: "100%", borderTopLeftRadius: "100%", borderTopRightRadius: "100%", opacity: 1}} variants={{BKwjpwvYJ: {opacity: 0.6}}} {...addPropertyOverrides({BKwjpwvYJ: {transformTemplate: transformTemplate1}}, baseVariant, gestureVariant)}/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-ZpznJ.framer-18jrtw3, .framer-ZpznJ .framer-18jrtw3 { display: block; }", ".framer-ZpznJ.framer-ywxmd1 { height: 48px; overflow: hidden; position: relative; width: 48px; }", ".framer-ZpznJ .framer-2tewi9 { flex: none; height: 32px; left: calc(50.00000000000002% - 32px / 2); overflow: visible; position: absolute; top: calc(50.00000000000002% - 32px / 2); width: 32px; }", ".framer-ZpznJ.framer-v-sj6sf.framer-ywxmd1 { aspect-ratio: 1 / 1; height: var(--framer-aspect-ratio-supported, 48px); }", ".framer-ZpznJ.framer-v-sj6sf .framer-2tewi9 { aspect-ratio: 1 / 1; height: var(--framer-aspect-ratio-supported, 48px); left: 50%; top: 50%; width: 48px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 48
 * @framerIntrinsicWidth 48
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"BKwjpwvYJ":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerirO5ZHQyf: React.ComponentType<Props> = withCSS(Component, css, "framer-ZpznJ") as typeof Component;
export default FramerirO5ZHQyf;

FramerirO5ZHQyf.displayName = "Custom Cursor";

FramerirO5ZHQyf.defaultProps = {height: 48, width: 48};

addPropertyControls(FramerirO5ZHQyf, {variant: {options: ["E2OQLO3U4", "BKwjpwvYJ"], optionTitles: ["Variant 1", "Variant 2"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerirO5ZHQyf, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})